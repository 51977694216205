import React, {useState} from 'react'
import Fade from 'react-reveal/Fade'
import Axios from "axios";
import {API_KEY, API_URL} from "../config";
import {LoaderSpinner} from "./LoaderSpinner";


const defaultHeaders = (apiKey) => ({
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
    'Accept-Language': 'es'
});

const submitContactInfo = async (data) => {
    const axios = Axios.create({
        baseURL: API_URL,
        headers: defaultHeaders(API_KEY)
    });

    return axios.post(`/contact`, data );
};

const Five = () => {
  const initialState =   {name: '', email: '', phone: '', message: '',};
  const [contactData, setContactData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const submit = async () =>  {
      setLoading(true);
      try {
           await submitContactInfo(contactData);
           setSuccess(true);
           setContactData(initialState);
           setTimeout( () => {
               setSuccess(true);
           }, 5000)
      } catch (e) {
          setError(true);
          setTimeout( () => {
             setError(false);
          }, 5000);
      } finally {
          setLoading(false);
      }

  };

  return (
  <section id="five" className="wrapper style2 special fade inactive">

    <Fade duration={2500}>
      <div className="container">
        <header>
          <h2>Hacé crecer tu negocio</h2>
          <p>Contactanos para dar un paso al próximo nivel</p>
            {error && <p>No se pudieron enviar los datos. Inténtelo más tarde.</p>}
            {success && <p>Hemos recibido tu consulta, nos pondremos en contacto a la brevedad. Muchas gracias.</p>}
        </header>
        <form onSubmit={e => { e.preventDefault(); submit(); }} className="cta">
          <div className="row gtr-uniform gtr-50" style={{justifyContent: `center`}}>
          <div className="col-8 col-12-xsmall">
              <input
                aria-label="Tu nombre"
                type="text"
                name="Nombre"
                required
                id="name"
                placeholder="Tu nombre"
                value={contactData.name}
                onChange={ (e) => {
                    const name = e.target.value;
                    setContactData( prev => ({...prev, name: name }));
                }}
              />
            </div>
          <div className="col-8 col-12-xsmall">
              <input
                  required
                aria-label="Your email"
                type="email"
                name="email"
                id="email"
                value={contactData.email}
                placeholder="Tu email"
                  onChange={ (e) => {
                      const email = e.target.value;
                      setContactData( prev => ({...prev, email: email }));
                  }}
              />
            </div>
            <div className="col-8 col-12-xsmall">
              <input
                aria-label="Teléfono"
                type="text"
                name="Telefono"
                id="phone"
                placeholder="Tu teléfono"
                value={contactData.phone}
                onChange={ (e) => {
                    const phone = e.target.value;
                    setContactData( prev => ({...prev, phone: phone }));
                }}
              />
            </div>
            <div className="col-8 col-12-xsmall">
              <textarea
                aria-label="Mensaje"
                type="text"
                name="Mensaje"
                id="message"
                placeholder="Mensaje"
                value={contactData.message}
                onChange={ (e) => {
                    const message = e.target.value;
                    setContactData( prev => ({...prev, message: message }));
                }}
              />
            </div>
            <div className="col-8 col-12-xsmall">
                {loading && <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <LoaderSpinner/>
                </div>}
                {!loading && <input
                aria-label="Submit the form"
                id="submitForm"
                type="submit"
                value="Contactar"
                className="fit primary"
              />}
            </div>
          </div>
        </form>
      </div>
    </Fade>
  </section>
)
  };
export default Five
